import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

import { SERVICE_ADMIN, callLambda } from "src/utils/lambda";

export const closeTask = ({ portalId, taskId }) => callLambda({
  method                : "post",
  service               : SERVICE_ADMIN,
  url                   : "closeTask",
  queryStringParameters : { portalId, taskId },
});

export const useCloseTask = ({ portalId }) => useSWRMutation(
  [portalId, "closeTask"],
  (url, { arg }) => closeTask({ portalId, taskId: arg.taskId }),
  {
    onSuccess: () => {
      mutate([portalId, "tasks"]);
    },
  }
);
