import { createSlice } from "@reduxjs/toolkit";
import { isHalloween } from "src/App";

const initialState = {
  initialLoading : true,
  currentDialog  : {
    name  : null,
    props : null,
  },
  themeMode: isHalloween() ? "dark" : "light"
};

const slice = createSlice({
  name     : "global",
  initialState,
  reducers : {
    setCurrentDialog      : (state, action) => ({ ...state, currentDialog: action.payload }),
    disableInitialLoading : state => ({ ...state, initialLoading: false }),
    toggleThemeMode       : state => ({
      ...state,
      themeMode: state.themeMode === "light" ? "dark" : "light"
    }),
    setThemeMode: (state, action) => ({
      ...state,
      themeMode: action.payload
    })
  }
});

export const {
  setCurrentDialog,
  disableInitialLoading,
  toggleThemeMode,
  setThemeMode
} = slice.actions;

export default slice.reducer;
