/* eslint-disable react/prop-types */
import { Box, Typography, ListItem, List, Avatar, ListItemButton, SvgIcon, ListItemText, ListItemAvatar, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { RouterLink, Scrollbar } from "src/components";
import { ROUTE_PATH, TASK_TYPE } from "src/constants";
import { useMemo, useState } from "react";
import { getTokenSymbol } from "src/utils/portal";

import { useCloseTask } from "src/services/closeTask";
import { get } from "lodash";
import { iconMaps, taskDescription, taskTitle } from "../../layout/vertical-layout/notifications-button/constants";

const Tasks = ({ taskItems, onClose, portalItem }) => {
  const tokenSymbol = useMemo(() => getTokenSymbol(portalItem), [portalItem]);

  const portalId = get(portalItem, "id", "");
  const { trigger: closeTask, isMutating: isLoading } = useCloseTask({ portalId });
  const [closingTaskId, setClosingTaskId] = useState(null);

  const handleCloseTask = async (taskId) => {
    setClosingTaskId(taskId);
    try {
      await closeTask({ taskId });
    }
    catch (error) {
      console.error("Failed to close task:", error);
    }
  };

  if (!taskItems.length) {
    return (
      <Box sx={{ px: 2, py: 1.5 }}>
        <Typography variant="body1">
          There are no tasks to do right now. You'll see a badge on this icon when there are.
        </Typography>
      </Box>
    );
  }

  return (
    <Scrollbar sx={{ height: "200px" }}>
      <List disablePadding>
        {taskItems.map((task) => {
          const { type: taskType } = task;
          const icon = iconMaps[taskType];
          let link = "";
          if ([TASK_TYPE.CHECK_BANK, TASK_TYPE.CHECK_SOLANA, TASK_TYPE.CHECK_ETH, TASK_TYPE.ISSUE_TOKENS].includes(taskType)) {
            link = `/p/${task.portal_id}/${ROUTE_PATH.FUNDRAISING.INVESTMENTS}/${task.investment_id}`;
          }
          else if (taskType === TASK_TYPE.ISSUE_GRANT_TOKENS) {
            link = `/p/${task.portal_id}/${ROUTE_PATH.GRANTS.EQUITY_GRANTS}/${task.grant_id}`;
          }
          else if (taskType === TASK_TYPE.SETTINGS) link = `/p/${task.portal_id}/${ROUTE_PATH.SETTINGS}?tab=${task.attributes.tab}`;
          else {
            link = `/p/${task.portal_id}/${ROUTE_PATH.STAKEHOLDERS}/${task.user_id}`;
            if (taskType === TASK_TYPE.REVIEW_AML) link += "?tab=aml";
            else if (taskType === TASK_TYPE.REVIEW_KYC) link += "?tab=kyc";
            else if (taskType === TASK_TYPE.REVIEW_KYB) link += "?tab=legal_entity";
          }
          return (
            <ListItem divider key={task.id} sx={{ position: "relative" }}>
              <ListItemButton
                component={RouterLink}
                href={link}
                onClick={onClose}
                sx={{ borderRadius: 1, px: 1, py: 0.5, }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <SvgIcon>{icon}</SvgIcon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={(
                    taskTitle(task, tokenSymbol, portalItem.company?.name)
                  )}
                  secondary={(
                    taskDescription(task, tokenSymbol, portalItem.company?.name)
                  )}
                />
              </ListItemButton>
              <Box sx={{
                width    : "auto",
                position : "absolute",
                top      : 10,
                right    : 20
              }}
              >
                <IconButton edge="end" onClick={() => handleCloseTask(get(task, "id", ""))}>
                  {isLoading && closingTaskId === task.id
                  ? <CircularProgress size={24} />
                  : <CloseIcon />}
                </IconButton>
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Scrollbar>
  );
};

export default Tasks;
