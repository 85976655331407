export const SWR_GET_USER_INFO_KEY = userId => (userId ? [userId, "getAdminUserInfo"] : null);

export const SWR_GET_ACCOUNT_INFO_KEY = (userId, portalId) => (userId && portalId ? [userId, portalId, "getAdminAccountInfo"] : null);

export const SWR_GET_ADMIN_PORTALS_KEY = userId => (userId ? [userId, "getAdminPortals"] : null);

export const SWR_GET_RISK_FACTORS_KEY = portalId => (portalId ? [portalId, "risk_factors"] : null);

export const SWR_GET_PORTAL_PRIVATE_KEY = portalId => (portalId ? [portalId, "portal_private"] : null);

export const SWR_GET_ADMIN_USERS_KEY = portalId => (portalId ? [portalId, "admin_users"] : null);

export const SWR_GET_DNS_STATUS_KEY = portalId => (portalId ? [portalId, "get_dns_status"] : null);

export const SWR_GET_SERIES_KEY = portalId => (portalId ? [portalId, "seriesItems"] : null);

export const SWR_GET_SERIES_NAV_ITEMS_KEY = portalId => (portalId ? [portalId, "seriesNavItems"] : null);

export const SWR_GET_FUNDRAISING_SERIES_KEY = portalId => (portalId ? [portalId, "fundRaisingItems"] : null);

export const SWR_GET_SERIES_DETAIL_KEY = (portalId, seriesId) => (portalId ? [{ portalId, seriesId }, "series_detail"] : null);

export const SWR_GET_INVESTMENT_DETAIL_KEY = (portalId, investmentId) => (portalId ? [{ portalId, investmentId, pageName: "investment_details" }, "investment_detail"] : null);

export const SWR_GET_STAKEHOLDER_DETAIL_KEY = (portalId, userId) => (portalId ? [{ portalId, userId, pageName: "stakeholder_details" }, "community_info"] : null);

export const SWR_GET_GRANT_DETAIL_KEY = (portalId, grantId) => (portalId ? [{ portalId, grantId, pageName: "grant_detail" }, "grant_detail"] : null);

export const SWR_GET_CAPTABLE_AI_RECORDS_KEY = (portalId, userId)  => (portalId && userId ? [{ portalId, userId }, "captableAiRecords"] : null);

export const SWR_UPDATE_PROMPT_KEY = userId => (userId ? [userId, "updatePrompt"] : null);

export const SWR_GET_S3_ASSETS = portalId => (portalId ? [portalId, "getS3Assets"] : null);

export const SWR_UPDATE_ASSET_NAME = assetId => (assetId ? [assetId, "updateAssetName"] : null);

export const SWR_HELLO_SIGN_PREVIEW = portalId => (portalId ? [{ portalId }, "hello_sign_preview"] : null);
