import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import BusinessIcon from "@mui/icons-material/Business";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SecurityIcon from "@mui/icons-material/Security";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import startCase from "lodash/startCase";

import { TASK_SUBTYPE, TASK_TYPE } from "src/constants";
import { numberWithFixedDecimal } from "src/utils/number";

export const iconMaps = {
  [TASK_TYPE.CHECK_BANK]         : <AccountBalanceIcon />,
  [TASK_TYPE.CHECK_SOLANA]       : <AccountBalanceWalletIcon />,
  [TASK_TYPE.CHECK_ETH]          : <AccountBalanceWalletIcon />,
  [TASK_TYPE.REVIEW_KYB]         : <BusinessIcon />,
  [TASK_TYPE.REVIEW_KYC]         : <HowToRegIcon />,
  [TASK_TYPE.REVIEW_AML]         : <SecurityIcon />,
  [TASK_TYPE.ISSUE_TOKENS]       : <TrendingUpIcon />,
  [TASK_TYPE.ISSUE_GRANT_TOKENS] : <EmojiEventsIcon />,
  [TASK_TYPE.SETTINGS]           : <SettingsIcon />,
};

export const taskTitle = (task, tokenSymbol, companyName) => {
  switch (task.type) {
  case TASK_TYPE.REVIEW_KYC:
    return "Identity review pending";
  case TASK_TYPE.REVIEW_AML:
    return "Potential AML hits found";
  case TASK_TYPE.REVIEW_KYB:
    return "Legal entity review pending";
  case TASK_TYPE.CHECK_BANK:
    return "Confirm payment was received";
  case TASK_TYPE.CHECK_SOLANA:
    return "Confirm funds received";
  case TASK_TYPE.CHECK_ETH:
    return "Confirm funds received";
  case TASK_TYPE.ISSUE_TOKENS:
  case TASK_TYPE.ISSUE_GRANT_TOKENS:
    return `${tokenSymbol} to be issued`;
  case TASK_TYPE.SETTINGS:
    switch (task.subtype) {
    case TASK_SUBTYPE.COMPANY_INFO:
      return `Details about ${companyName} needed`;
    case TASK_SUBTYPE.EMAILS:
      return "Add the emails to use";
    case TASK_SUBTYPE.SOCIAL:
      return `Social media accounts of ${companyName} needed`;
    case TASK_SUBTYPE.LEGAL:
      return "Add links towards your legal notices";
    case TASK_SUBTYPE.BRANDING:
      return "Update with your branding";
    case TASK_SUBTYPE.BANK_INFO:
      return "Bank information needed";
    case TASK_SUBTYPE.DNS:
      return "Setup your custom domain";
    default:
      return "";
    }
  default:
    return task.type;
  }
};

const CARD_NAME = {
  [TASK_SUBTYPE.COMPANY_INFO] : "Company information",
  [TASK_SUBTYPE.EMAILS]       : "Emails",
  [TASK_SUBTYPE.SOCIAL]       : "Social media accounts",
  [TASK_SUBTYPE.LEGAL]        : "Legal information",
  [TASK_SUBTYPE.BRANDING]     : "Branding",
};

export const taskDescription = (task, tokenSymbol, companyName) => {
  const { type, attributes, app_user: userInfo, amount, dollar_amount: dollarAmount } = task;
  const fullName = startCase(`${userInfo.firstname} ${userInfo.lastname}`.toLowerCase());

  switch (type) {
  case TASK_TYPE.REVIEW_KYC:
    if (attributes?.review_kyc && attributes?.review_accreditation_proof) {
      return `The identity and accreditation proof of ${fullName} (${userInfo.email}) need to be reviewed.`;
    }
    if (attributes?.review_kyc) {
      return `The identity of ${fullName} (${userInfo.email}) needs to be reviewed.`;
    }
    if (attributes?.review_accreditation_proof) {
      return `The accreditation proof of ${fullName} (${userInfo.email}) needs to be reviewed.`;
    }
    return "";
  case TASK_TYPE.REVIEW_AML:
    return `Potential hits for money laundering were found for ${fullName} (${userInfo.email}). Review them and decide if ok to allow to invest.`;
  case TASK_TYPE.REVIEW_KYB:
    if (attributes?.review_kyb && attributes?.review_accreditation_proof) {
      return `The legal entity ${fullName} (${userInfo.email}) is ready to be reviewed, including its accreditation proof.`;
    }
    if (attributes?.review_kyb) {
      return `The legal entity ${fullName} (${userInfo.email}) is ready to be reviewed.`;
    }
    if (attributes?.review_accreditation_proof) {
      return `The accreditation proof of the legal entity ${fullName} (${userInfo.email}) needs to be reviewed.`;
    }
    return "";
  case TASK_TYPE.CHECK_BANK:
    return `${fullName} (${userInfo.email}) initiated a bank transfer of $${numberWithFixedDecimal(amount)}. Confirm the investment once you receive the funds in your bank account.`;
  case TASK_TYPE.CHECK_SOLANA:
    return `${fullName} (${userInfo.email}) initiated a transfer of $${numberWithFixedDecimal(amount)} to your Solana wallet. Please confirm the investment once you have received the funds.`;
  case TASK_TYPE.CHECK_ETH:
    return `${fullName} (${userInfo.email}) initiated a transfer of $${numberWithFixedDecimal(amount)} to your Ethereum (L1) wallet. Please confirm the investment once you have received the funds.`;
  case TASK_TYPE.ISSUE_TOKENS:
    return `${fullName} (${userInfo.email}) is waiting to receive the ${tokenSymbol} for their $${numberWithFixedDecimal(amount)} investment. Issue them now.`;
  case TASK_TYPE.ISSUE_GRANT_TOKENS:
    return `${fullName} (${userInfo.email}) is waiting to receive the ${tokenSymbol} for their $${numberWithFixedDecimal(dollarAmount)} equity grant. Issue them now.`;
  case TASK_TYPE.SETTINGS:
    switch (task.subtype) {
    case TASK_SUBTYPE.COMPANY_INFO:
    case TASK_SUBTYPE.EMAILS:
    case TASK_SUBTYPE.SOCIAL:
    case TASK_SUBTYPE.LEGAL:
    case TASK_SUBTYPE.BRANDING:
      return `Please fill in the necessary details on the Settings page under the ${CARD_NAME[task.subtype]} card.`;
    case TASK_SUBTYPE.BANK_INFO:
      return `Add ${companyName}'s bank details for smooth transactions. To offer your investors more payment options, also add an Ethereum wallet.`;
    case TASK_SUBTYPE.DNS:
      return "Ensure that all your fundraising activities happen under your trusted domain name.";
    default:
      return "";
    }
  default:
    return "";
  }
};
