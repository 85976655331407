import { useEffect, useState } from "react";
import { CircularProgress, Box, Typography, Fade } from "@mui/material";

import Logo from "src/assets/images/default/FairmintLogo.svg";

function GradientCircularProgress() {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
    </>
  );
}

export default function FairmintLogoLoadingSpinner() {
  const quotes = [
    "Cap tables: Because spreadsheets needed a midlife crisis too.",
    "Making money from managing captables is like making money from opening a bank account.",
    "If your VCs still make you use Carta, their last win was Airbnb.",
    "Equity management smoother than your CEO's pitch to VCs.",
    "If your cap table is in Excel, your exit strategy might be in jeopardy.",
    "We make cap tables sexier than your pitch deck.",
    "Cap tables shouldn't be more complex than your product.",
    "If your cap table is giving you more headaches than your Series A negotiations, you're doing it wrong.",
    "If Carta is Web 2.0, we're Web3 - minus the crypto bros and questionable JPEGs.",
    "Making stock options less mysterious than your CTO's latest tech stack decision.",
  ];

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(Math.floor(Math.random() * quotes.length));
  const [showQuote, setShowQuote] = useState(false);

  useEffect(() => {
    const quoteTimer = setInterval(() => {
      setShowQuote(false);
      setTimeout(() => {
        setCurrentQuoteIndex(prevIndex => (prevIndex + 1) % quotes.length);
        setShowQuote(true);
      }, 1000); // Wait for fade out before changing quote
    }, 5000); // Change quote every 5 seconds

    // Show the first quote after a short delay
    const initialTimer = setTimeout(() => {
      setShowQuote(true);
    }, 500);

    return () => {
      clearInterval(quoteTimer);
      clearTimeout(initialTimer);
    };
  }, [quotes.length]);

  return (
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={Logo} alt="Fairmint logo" style={{ height: "3rem", marginBottom: "1rem" }} />
        <GradientCircularProgress />
        <Box mt={3} height="3rem"> {/* Fixed height to prevent layout shift */}
          <Fade in={showQuote} timeout={1000}>
            <Typography fontSize="9px" sx={{ width: "250px", wordWrap: "break-word", textAlign: "center" }}>
              {quotes[currentQuoteIndex]}
            </Typography>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
}

export function HalloweenLogoLoadingSpinner() {
  const spookyQuotes = [
    "Beware the ghost of diluted equity past... 👻",
    "Your cap table is scarier than any horror movie 🎃",
    "Term sheets from hell: Where founder-friendly goes to die ☠️",
    "The scariest part? Your equity structure wasn't reviewed by lawyers 🧟",
    "Trick or treat? Your option pool is running out... 🦇",
    "Nothing scarier than finding a dead-equity holder 💀",
    "The phantom shares are coming from inside the cap table! 😱",
    "Your exit strategy is giving me nightmares 🎃",
    "Dracula's terms were more founder-friendly than your last round 🧛",
    "The only thing scarier than a down round? Your messy cap table 🕸️",
  ];

  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(Math.floor(Math.random() * spookyQuotes.length));
  const [showQuote, setShowQuote] = useState(false);

  useEffect(() => {
    const quoteTimer = setInterval(() => {
      setShowQuote(false);
      setTimeout(() => {
        setCurrentQuoteIndex(prevIndex => (prevIndex + 1) % spookyQuotes.length);
        setShowQuote(true);
      }, 1000);
    }, 5000);

    const initialTimer = setTimeout(() => {
      setShowQuote(true);
    }, 500);

    return () => {
      clearInterval(quoteTimer);
      clearTimeout(initialTimer);
    };
  }, []);

  return (
    <Box
      sx={{
        display        : "flex",
        justifyContent : "center",
        alignItems     : "center",
        height         : "100vh",
        background     : `linear-gradient(180deg, #0a0a15 0%, #1a0f1f 100%)`,
        transition     : "all 0.3s ease-in-out"
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          src={Logo}
          alt="Fairmint logo"
          style={{
            height       : "3rem",
            marginBottom : "1rem",
            filter       : `drop-shadow(0 0 15px #6b30ff)`
          }}
        />
        <Box mt={2} display="flex" gap={2}>
          {[...Array(3)].map((_, i) => (
            <Box
              key={i}
              component="span"
              sx={{
                animation          : "float 3s ease-in-out infinite",
                animationDelay     : `${i * 0.5}s`,
                fontSize           : "2rem",
                "@keyframes float" : {
                  "0%, 100%": {
                    transform : "translateY(0)",
                    filter    : `drop-shadow(0 0 10px #ff6600)`
                  },
                  "50%": {
                    transform : "translateY(-15px)",
                    filter    : `drop-shadow(0 0 20px #ff6600)`
                  }
                }
              }}
            >
              🎃
            </Box>
          ))}
        </Box>
        <Box mt={3} height="3rem">
          <Fade in={showQuote} timeout={1000}>
            <Typography
              fontSize="16px"
              sx={{
                width      : "300px",
                wordWrap   : "break-word",
                textAlign  : "center",
                color      : "#ff8533",
                textShadow : `0 0 12px rgba(255, 102, 0, 0.7), 0 0 20px rgba(0, 0, 0, 0.4)`,
                fontFamily : "'Creepster', cursive",
                transition : "all 0.3s ease-in-out",
                "&:hover"  : {
                  color      : "#ff4400",
                  textShadow : `0 0 15px rgba(255, 68, 0, 0.8), 0 0 25px rgba(255, 102, 0, 0.6)`
                }
              }}
            >
              {spookyQuotes[currentQuoteIndex]}
            </Typography>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
}
