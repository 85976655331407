import { Suspense, lazy } from "react";

import { LoadingSpinner } from "src/components";
import { DIALOG_NAME } from "src/constants";
import useGlobalDialog from "src/hooks/use-globaldialog";

const SeriesTypeDialog = lazy(() => import("src/components/Dialogs/SeriesTypeDialog"));
const CreateSeriesDialog = lazy(() => import("src/components/Dialogs/CreateSeriesDialog"));
const ChatDialog  = lazy(() => import("src/components/Dialogs/ChatDialog"));
const SeriesPublishReadyDialog = lazy(() => import("src/components/Dialogs/SeriesPublishReadyDialog"));
const SeriesPublishCommitmentDialog = lazy(() => import("src/components/Dialogs/SeriesPublishCommitmentDialog"));
const ConfirmSeriesPublishDialog = lazy(() => import("src/components/Dialogs/ConfirmSeriesPublishDialog"));
const SuccessSeriesPublishDialog = lazy(() => import("src/components/Dialogs/SuccessSeriesPublishDialog"));
const ImportCapTableDialog = lazy(() => import("src/components/Dialogs/ImportCapTableDialog"));
const SuccessImportCapTableDialog = lazy(() => import("src/components/Dialogs/SuccessImportCapTableDialog"));
const UpgradeFairmintOneDialog = lazy(() => import("src/components/Dialogs/UpgradeFairmintOneDialog"));
const BrandingDialog = lazy(() => import("src/components/Dialogs/BrandingDialog"));
const UpdatePitchDeckDialog = lazy(() => import("src/components/Dialogs/UpdatePitchDeckDialog"));
const GrantEquityDialog = lazy(() => import("src/components/Dialogs/GrantEquityDialog"));
const ExploreCaptableDialog = lazy(() => import("src/components/Dialogs/ExploreCaptableDialog"));

const DialogWrapper = () => {
  const { data: currentDialog, setData: setCurrentDialog } = useGlobalDialog();

  // No open dialog
  if (!currentDialog?.name) return null;

  // Render
  return (
    <Suspense fallback={<LoadingSpinner />}>
      {currentDialog.name === DIALOG_NAME.SERIES_TYPE && <SeriesTypeDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.CREATE_SERIES && <CreateSeriesDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.SERIES_PUBLISH_READY && <SeriesPublishReadyDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.SERIES_PUBLISH_COMMITMENT && (
        <SeriesPublishCommitmentDialog onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.CONFIRM_SERIES_PUBLISH && <ConfirmSeriesPublishDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.SUCCESS_SERIES_PUBLISH && <SuccessSeriesPublishDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.IMPORT_CAP_TABLE && <ImportCapTableDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.SUCCESS_IMPORT_CAP_TABLE && (
        <SuccessImportCapTableDialog onClose={() => setCurrentDialog(null)} />
      )}
      {currentDialog.name === DIALOG_NAME.UPGRADE_FAIRMINT_ONE && <UpgradeFairmintOneDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.BRANDING && <BrandingDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.AI && <ChatDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.UPDATE_PITCH_DECK && <UpdatePitchDeckDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.GRANT_EQUITY && <GrantEquityDialog onClose={() => setCurrentDialog(null)} />}
      {currentDialog.name === DIALOG_NAME.EXPLORE_CAPTABLE && <ExploreCaptableDialog onClose={() => setCurrentDialog(null)} />}
    </Suspense>
  );
};

export default DialogWrapper;
