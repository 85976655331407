import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Box, ButtonBase, Collapse, SvgIcon, useMediaQuery } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { RouterLink } from "src/components";

export const SideNavItem = (props) => {
  const {
    active,
    children,
    depth = 0,
    disabled,
    external,
    icon,
    label,
    open: openProp,
    path,
    title,
    action,
  } = props;
  const [open, setOpen] = useState(!!openProp);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.up("lg") && theme.breakpoints.down("xl"));

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, []);

  // Icons can be defined at top level only, deep levels have bullets instead of actual icons.

  let startIcon;

  if (depth === 0) {
    startIcon = icon;
  }
  else {
    startIcon = (
      <Box
        sx={{
          alignItems     : "center",
          display        : "center",
          height         : 20,
          justifyContent : "center",
          width          : 24
        }}
      >
        <Box
          sx={{
            backgroundColor : "var(--nav-item-icon-color)",
            borderRadius    : "50%",
            height          : 4,
            opacity         : 0,
            width           : 4,
            ...(active && {
              backgroundColor : "transparent",
              height          : 6,
              opacity         : 1,
              width           : 6
            })
          }}
        />
      </Box>
    );
  }

  const offset = depth === 0 ? 0 : (depth - 1) * 16;

  // Branch

  if (children) {
    return (
      <li>
        <ButtonBase
          disabled={disabled}
          onClick={handleToggle}
          sx={{
            alignItems     : "center",
            borderRadius   : 1,
            display        : "flex",
            justifyContent : "flex-start",
            pl             : isSmallScreen ? `${12 + offset}px` : `${16 + offset}px`,
            pr             : isSmallScreen ? 1.5 : 2,
            py             : "6px",
            textAlign      : "left",
            width          : "100%",
            ...(active && {
              ...(depth === 0 && {
                backgroundColor: "var(--nav-item-active-bg)"
              })
            }),
            "&:hover": {
              backgroundColor: "var(--nav-item-hover-bg)"
            },
          }}
        >
          {startIcon && (
            <Box
              component="span"
              sx={{
                alignItems     : "center",
                color          : "var(--nav-item-icon-color)",
                fill           : "var(--nav-item-icon-color)",
                display        : "inline-flex",
                justifyContent : "center",
                mr             : isSmallScreen ? 1.5 : 2,
                ...(active && {
                  color : "var(--nav-item-icon-active-color)",
                  fill  : "var(--nav-item-icon-active-color)",
                })
              }}
            >
              {startIcon}
            </Box>
          )}
          <Box
            component="span"
            sx={{
              color      : "var(--nav-item-color)",
              flexGrow   : 1,
              fontFamily : theme => theme.typography.fontFamily,
              fontSize   : depth > 0 ? 13 : 14,
              fontWeight : depth > 0 ? 500 : 600,
              lineHeight : "24px",
              whiteSpace : "nowrap",
              ...(active && {
                color: "var(--nav-item-active-color)"
              }),
              ...(disabled && {
                color: "var(--nav-item-disabled-color)"
              })
            }}
          >
            {typeof title === "function" ? title({ isSelected: active }) : title}
          </Box>
          <SvgIcon
            sx={{
              color    : "var(--nav-item-chevron-color)",
              fontSize : 16,
              ml       : 2
            }}
          >
            {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </SvgIcon>
        </ButtonBase>
        <Collapse
          in={open}
          sx={{ mt: 0.5 }}
        >
          {children}
        </Collapse>
      </li>
    );
  }

  // Leaf

  // eslint-disable-next-line no-nested-ternary
  const linkProps = path
    ? external
      ? {
        component : "a",
        href      : path,
        target    : "_blank"
      }
      : {
        component : RouterLink,
        href      : path
      }
    : {};
  if (action) {
    linkProps.onClick = action;
  }

  return (
    <li>
      <ButtonBase
        disabled={disabled}
        sx={{
          alignItems     : "center",
          borderRadius   : 1,
          display        : "flex",
          justifyContent : "flex-start",
          pl             : isSmallScreen ? `${12 + offset}px` : `${16 + offset}px`,
          pr             : isSmallScreen ? 1.5 : 2,
          py             : "6px",
          textAlign      : "left",
          width          : "100%",
          ...(active && {
            ...(depth === 0 && {
              backgroundColor: "var(--nav-item-active-bg)"
            })
          }),
          "&:hover": {
            backgroundColor: "var(--nav-item-hover-bg)"
          }
        }}
        {...linkProps}
      >
        {startIcon && (
          <Box
            component="span"
            sx={{
              alignItems     : "center",
              color          : "var(--nav-item-icon-color)",
              fill           : "var(--nav-item-icon-color)",
              display        : "inline-flex",
              justifyContent : "center",
              mr             : isSmallScreen ? 1.5 : 2,
              ...(active && {
                color : "var(--nav-item-icon-active-color)",
                fill  : "var(--nav-item-icon-active-color)",
              })
            }}
          >
            {startIcon}
          </Box>
        )}
        <Box
          component="span"
          sx={{
            color      : "var(--nav-item-color)",
            flexGrow   : 1,
            fontFamily : theme => theme.typography.fontFamily,
            fontSize   : depth > 0 ? 13 : 14,
            fontWeight : depth > 0 ? 500 : 600,
            lineHeight : "24px",
            whiteSpace : "nowrap",
            ...(active && {
              color: "var(--nav-item-active-color)"
            }),
            ...(disabled && {
              color: "var(--nav-item-disabled-color)"
            })
          }}
        >
          {typeof title === "function" ? title({ isSelected: active }) : title}
        </Box>
        {label && (
          <Box
            component="span"
            sx={{ ml: 2 }}
          >
            {label}
          </Box>
        )}
      </ButtonBase>
    </li>
  );
};

SideNavItem.propTypes = {
  active   : PropTypes.bool,
  children : PropTypes.node,
  depth    : PropTypes.number,
  disabled : PropTypes.bool,
  label    : PropTypes.string,
  external : PropTypes.bool,
  icon     : PropTypes.node,
  open     : PropTypes.bool,
  path     : PropTypes.string,
  title    : PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  action   : PropTypes.func,
};
