import { useMemo } from "react";
import get from "lodash/get";
import { Tooltip } from "@mui/material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";

import { DIALOG_NAME, ROUTE_PATH, SERIES_STATUS, SERIES_TYPE } from "src/constants";
import { SeriesNavIcon } from "src/components/Icons";

const statusDisplayMapping = {
  [SERIES_STATUS.SETUP]           : "Setup",
  [SERIES_STATUS.READY]           : "Ready",
  [SERIES_STATUS.LIVE]            : "Live",
  [SERIES_STATUS.PAUSED]          : "Paused",
  [SERIES_STATUS.CLOSED]          : "Closed",
  [SERIES_STATUS.SOFT_COMMITMENT] : "Soft Commitment",
  [SERIES_STATUS.IMPORTED]        : "Imported",
};

// eslint-disable-next-line react/prop-types
const Title = ({ name, status, isSelected = false }) => (
  <span style={{ display    : "flex",
    alignItems : "center",
    gap        : "2px",
    fontWeight : isSelected ? 600 : "inherit",
    opacity    : isSelected ? 1 : 0.75
  }}
  >
    <Tooltip title={statusDisplayMapping[status]} arrow placement="left">
      {name}
    </Tooltip>
  </span>
);

export const useSections = ({ seriesItems, portalItem, setCurrentDialog }) => useMemo(
  () => {
    if (!portalItem || !seriesItems) return [];
    const { id: portalId } = portalItem;

    const noSeries = seriesItems.length === 0;
    const noShares = seriesItems.filter(item => item.type === SERIES_TYPE.SHARES).length === 0;
    const noWarrants = seriesItems.filter(item => item.type === SERIES_TYPE.WARRANT).length === 0;
    const isMasterFund = get(portalItem, "attributes.isMasterFund", false);
    const isVentureFund = get(portalItem, "attributes.isVentureFund", false);

    const seriesItemsSection = seriesItems
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map((seriesItem) => {
        if (seriesItem.type === SERIES_TYPE.FUNDRAISING) {
          return {
            title : ({ isSelected }) => <Title name={seriesItem.name} status={seriesItem.status} isSelected={isSelected} />,
            path  : `/p/${portalId}/${ROUTE_PATH.SERIES}/${seriesItem.id}`,
          };
        }

        if (seriesItem.type === SERIES_TYPE.VERIFICATION)  {
          return {
            title : `${seriesItem.name}`,
            path  : `/p/${portalId}/${ROUTE_PATH.SERIES}/${seriesItem.id}`,
          };
        }
        return {};
      });

    const warrantsItemsSection = seriesItems
      .filter(seriesItem => seriesItem.type === SERIES_TYPE.WARRANT)
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map(seriesItem => ({
        title : ({ isSelected }) => <Title name={seriesItem.name} status={seriesItem.status} isSelected={isSelected} />,
        path  : `/p/${portalId}/${ROUTE_PATH.WARRANT}/${seriesItem.id}`,
      }));

    const grantsItemsSection = seriesItems
      .filter(seriesItem => seriesItem.type === SERIES_TYPE.GRANT)
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map(seriesItem => ({
        title : ({ isSelected }) => <Title name={seriesItem.name} status={seriesItem.status} isSelected={isSelected} />,
        path  : `/p/${portalId}/${ROUTE_PATH.GRANT}/${seriesItem.id}`,
      }));

    const noGrants = grantsItemsSection.length === 0;

    if (noSeries) {
      seriesItemsSection.push({
        title  : "New Series",
        action : () => setCurrentDialog(DIALOG_NAME.SERIES_TYPE, {
          type: "new_series", portalItem
        }) });
    }

    const sharesItemsSection = seriesItems
      .filter(seriesItem => seriesItem.type === SERIES_TYPE.SHARES)
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .map(seriesItem => ({
        title : ({ isSelected }) => <Title name={seriesItem.name} status={seriesItem.status} isSelected={isSelected} />,
        path  : `/p/${portalId}/${ROUTE_PATH.SHARES}/${seriesItem.id}`,
      }));

    if (noShares) {
      sharesItemsSection.push({
        title  : "Import cap table",
        action : () => setCurrentDialog(DIALOG_NAME.IMPORT_CAP_TABLE, {
          type: "IMPORT_CAP_TABLE", portalItem
        }) });
    }

    if (isMasterFund || isVentureFund) { // The portal is used to create SPVs
      return [
        {
          items: [
            {
              title : "Dashboard",
              path  : `/p/${portalId}/${ROUTE_PATH.DASHBOARD}`,
              icon  : <RocketLaunchIcon />,
            },
            {
              title : "Stakeholders",
              path  : `/p/${portalId}/${ROUTE_PATH.STAKEHOLDERS}`,
              icon  : <PeopleAltOutlinedIcon />,
            },
            {
              title : "Investments",
              path  : `/p/${portalId}/${ROUTE_PATH.FUNDRAISING.INVESTMENTS}`,
              icon  : <PaidOutlinedIcon />,
            },
            {
              title : isMasterFund ? "SPVs" : "Funds",
              path  : `/p/${portalId}/${ROUTE_PATH.SERIES}`,
              icon  : <SeriesNavIcon />,
              items : seriesItemsSection,
            },
            {
              title : "Data Room",
              path  : `/p/${portalId}/${ROUTE_PATH.DATA_ROOM}`,
              icon  : <DatasetOutlinedIcon />,
            },
          ],
        },
      ];
    }
    return [
      {
        items: [
          {
            title : "Dashboard",
            path  : `/p/${portalId}/${ROUTE_PATH.DASHBOARD}`,
            icon  : <RocketLaunchIcon />,
          },
          {
            title : "Stakeholders",
            path  : `/p/${portalId}/${ROUTE_PATH.STAKEHOLDERS}`,
            icon  : <PeopleAltOutlinedIcon />,
          },
          {
            title : "Investments",
            path  : `/p/${portalId}/${ROUTE_PATH.FUNDRAISING.INVESTMENTS}`,
            icon  : <PaidOutlinedIcon />,
          },
          {
            title : "Cap Table",
            path  : `/p/${portalId}/${ROUTE_PATH.CAPTABLE.SUMMARY}`,
            icon  : <PieChartOutlineIcon />,
          },
          {
            title    : "Shares",
            path     : `/p/${portalId}/${ROUTE_PATH.SHARES}`,
            icon     : <SeriesNavIcon />,
            items    : sharesItemsSection,
            disabled : noShares
          },
          {
            title    : "SAFEs",
            path     : `/p/${portalId}/${ROUTE_PATH.SERIES}`,
            icon     : <SeriesNavIcon />,
            items    : seriesItemsSection,
            disabled : noSeries
          },
          {
            title    : "Warrants",
            path     : `/p/${portalId}/${ROUTE_PATH.WARRANT}`,
            icon     : <SeriesNavIcon />,
            items    : warrantsItemsSection,
            disabled : noWarrants
          },
          {
            title    : "Grants",
            path     : `/p/${portalId}/${ROUTE_PATH.GRANTS.EQUITY_GRANTS}`,
            icon     : <WorkspacePremiumOutlinedIcon />,
            items    : grantsItemsSection,
            disabled : noGrants
          },
          {
            title : "Tax & Compliance",
            path  : `/p/${portalId}/${ROUTE_PATH.TAX.INDEX}`,
            icon  : <AssuredWorkloadOutlinedIcon />,
            items : [
              {
                title : "Filings",
                path  : `/p/${portalId}/${ROUTE_PATH.TAX.FILINGS}`,
              },
              {
                title : "Risk factors",
                path  : `/p/${portalId}/${ROUTE_PATH.TAX.RISK_FACTORS}`,
              },
              {
                title : "State fees",
                path  : `/p/${portalId}/${ROUTE_PATH.TAX.STATE_FEES}`,
              },
              {
                title : "409a Report",
                path  : `/p/${portalId}/${ROUTE_PATH.TAX.VALUTATION_REPORT}`,
              },
            ],
          },
          {
            title : "Data Room",
            path  : `/p/${portalId}/${ROUTE_PATH.DATA_ROOM}`,
            icon  : <DatasetOutlinedIcon />,
          },
        ],
      },
    ];
  },
  [seriesItems, portalItem, setCurrentDialog]
);
