import { useEffect, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import get from "lodash/get";
import { ROUTE_PATH, ROLE_IN_PORTAL, PORTAL_STATUS } from "src/constants";
import { usePathname } from "src/hooks";
import useGlobalDialog from "src/hooks/use-globaldialog";
import { useGetAdminAccountInfo } from "src/services/getAdminAccountInfo";
import { useGetSeriesNavItems } from "src/services/getAdminContent";
import withUserItem from "src/hoc/withUserItem";
import Messenger from "src/utils/messenger";

import { useSections } from "./config";
import VerticalLayout from "./vertical-layout";

const PortalLayout = ({ children, userItem }) => {
  const { portalId, seriesId } = useParams();
  const pathname = usePathname();
  const { setData: setCurrentDialog } = useGlobalDialog();
  const { data: adminAccountInfo, error } = useGetAdminAccountInfo(userItem?.id, portalId);
  const { data: seriesNavInfo } = useGetSeriesNavItems({ portalId });

  const { accountItem, portalItem } = useMemo(() => adminAccountInfo || {}, [adminAccountInfo]);
  const seriesItems = useMemo(() => seriesNavInfo?.seriesNames || [], [seriesNavInfo]);

  const sections = useSections({ seriesItems, portalItem, setCurrentDialog });

  useEffect(() => {
    if (userItem) {
      Messenger.loadMessenger(userItem, portalItem);
    }
    Sentry.setUser({
      id    : get(userItem, "id"),
      email : get(userItem, "email"),
    });
  }, [userItem, portalItem]);

  // Set tags for sentry
  useEffect(() => {
    if (seriesId) {
      Sentry.setTag("series_id", seriesId);
    }
    // set portal id
    if (portalId) {
      Sentry.setTag("portal_id", portalId);
    }
  }, [seriesId, portalId]);

  if (!accountItem || !portalItem) {
    if (error) {
      return <Navigate to={ROUTE_PATH.PORTALS} state={{ wrongPortal: true }} />;
    }
    return null;
  }

  if (error || ![ROLE_IN_PORTAL.OWNER, ROLE_IN_PORTAL.ADMIN].includes(accountItem.role_in_portal)) {
    return <Navigate to={ROUTE_PATH.PORTALS} />;
  }

  // Check onboarding status
  if (
    portalItem.status === PORTAL_STATUS.NEW
    && pathname !== `/p/${portalId}/${ROUTE_PATH.ONBOARDING}`
  ) {
    return <Navigate to={`/p/${portalId}/${ROUTE_PATH.ONBOARDING}`} />;
  }

  if (
    portalItem.status !== PORTAL_STATUS.NEW
    && !userItem.firstname
    && pathname !== `/p/${portalId}/${ROUTE_PATH.WELCOME_INVITE}`
  ) {
    return <Navigate to={`/p/${portalId}/${ROUTE_PATH.WELCOME_INVITE}`} />;
  }

  // Redirect navigation for old risk-factors page
  if (pathname === `/p/${portalId}/fundraising/risk-factors`) {
    return <Navigate to={`/p/${portalId}/${ROUTE_PATH.TAX.RISK_FACTORS}`} />;
  }

  // Redirect navigation for old series pages
  if (seriesId) {
    if (pathname === `/p/${portalId}/captable/${ROUTE_PATH.SERIES}/${seriesId}`) {
      return <Navigate to={`/p/${portalId}/${ROUTE_PATH.SERIES}/${seriesId}`} />;
    }
    if (pathname === `/p/${portalId}/captable/${ROUTE_PATH.SERIES_DEAL}/${seriesId}`) {
      return <Navigate to={`/p/${portalId}/${ROUTE_PATH.SERIES_DEAL}/${seriesId}`} />;
    }
    if (pathname === `/p/${portalId}/captable/${ROUTE_PATH.SERIES_PREVIEW}/${seriesId}`) {
      return <Navigate to={`/p/${portalId}/${ROUTE_PATH.SERIES_PREVIEW}/${seriesId}`} />;
    }
  }

  if (
    pathname === `/p/${portalId}/${ROUTE_PATH.ONBOARDING}`
    || pathname === `/p/${portalId}/${ROUTE_PATH.WELCOME_INVITE}`
    || pathname === `/p/${portalId}/${ROUTE_PATH.WELCOME}`
    || pathname.includes(`/p/${portalId}/${ROUTE_PATH.SERIES_DEAL}`)
    || pathname.includes(`/p/${portalId}/${ROUTE_PATH.SERIES_PREVIEW}`)
  ) {
    return <>{children}</>;
  }

  return (
    <VerticalLayout sections={sections} navColor="discreet">
      {children}
    </VerticalLayout>
  );
};

PortalLayout.propTypes = {
  children : PropTypes.node.isRequired,
  userItem : PropTypes.object,
};

export default withUserItem(PortalLayout);
