import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { init as SentryInit, replayIntegration } from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
// import { browserTracingIntegration } from "@sentry/browser";
import TagManager from "react-gtm-module";
import { SWRConfig } from "swr";
import { Toaster } from "react-hot-toast";
import get from "lodash/get";
import store from "./store";
import {
  config_REACT_APP_STAGE,
  config_gtmId,
  config_sentryDSN
} from "./config";
import App from "./App";
import { APP_STAGE } from "./constants";

const integrations = [
  captureConsoleIntegration({ levels: ["error"] }),
];

if (config_REACT_APP_STAGE !== APP_STAGE.DEV) {
  integrations.push(replayIntegration({
    maskAllText   : false,
    blockAllMedia : false,
  }));
}

SentryInit({
  environment              : config_REACT_APP_STAGE,
  dsn                      : config_sentryDSN,
  integrations,
  maskAllText              : false,
  denyUrls                 : ["/node_modules/"],
  sendDefaultPii           : true,
  tracesSampleRate         : 0.2,
  replaysSessionSampleRate : 0.0,
  replaysOnErrorSampleRate : 1.0,
  profilesSampleRate       : 0.0,
  beforeSend(event) {
    // Check if the hostname is localhost
    if (window.location.hostname === "localhost") {
      return null;
    }
    if (event.exception && event.exception.values) {
      const exceptionMessage = get(event, "exception.values[0].value");
      if (exceptionMessage && (
        exceptionMessage.includes("getSelection is not a function")
        || exceptionMessage.includes("Can't find variable: webkit")
        || exceptionMessage.includes("Object doesn't support property or method 'closest'")
      )) {
        return null; // Implication: We won't receive errors caused by browser extensions ie. metamask
      }
    }
    return event;
  },
});

if (config_gtmId) {
  TagManager.initialize({ gtmId: config_gtmId });
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <SWRConfig value={{
          revalidateIfStale     : false,
          revalidateOnFocus     : false,
          revalidateOnReconnect : false,
          errorRetryCount       : 0
        }}
        >
          <App />
          <Toaster />
        </SWRConfig>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);
